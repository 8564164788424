var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import { useNotificateForUnsavedForm } from '@/hooks/useNotificateForUnsavedForm';
import { useUpdateDraft } from '../interactive-form/useUpdateDraft';
import styles from './styles.module.scss';
import { useAddFlow } from './useAddFlow';
import { useCreateDraft } from './useCreateDraft';
import { useInvalidateFlow } from './useInvalidateFlow';
import { usePublishFlow } from './usePublishFlow';
import { useSetFlow } from './useSetFlow';
export const CreateFlowHeader = ({ flow, triggerType = 0, currentNodes = [], currentEdges = [], isRenameMode = false, onSetRenameModeOff, isConvertMode = false, }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [skipLocationChangeWarning, setSkipLocationChangeWarning] = useState(false);
    const titleInput = useRef(null);
    const [title, setTitle] = useState((flow === null || flow === void 0 ? void 0 : flow.title) || '');
    const [hasError, setHasError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    useEffect(() => {
        setTitle((flow === null || flow === void 0 ? void 0 : flow.title) || '');
    }, [flow === null || flow === void 0 ? void 0 : flow.title]);
    const reloadForm = (id) => {
        setSkipLocationChangeWarning(true);
        setTimeout(() => {
            const path = generatePath(ROUTES.FLOWS_EDIT, { flowId: id.toString() });
            navigate(path);
        }, 1);
    };
    const { createDraft, isCreatingDraft } = useCreateDraft({
        triggerType,
        nodes: currentNodes,
        edges: currentEdges,
    });
    const { updateDraft, isUpdatingDraft } = useUpdateDraft();
    const { publishFlow, isPublishingFlow } = usePublishFlow();
    const { updateFlow, isUpdatingFlow } = useSetFlow();
    const { addFlow, isAddingFlow } = useAddFlow();
    const { invalidateFlow, isInvalidatingFlow } = useInvalidateFlow(flow === null || flow === void 0 ? void 0 : flow.id);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsSubmitted(true);
        if (!title.trim()) {
            setHasError(true);
            (_a = titleInput.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        if (isConvertMode && flow) {
            const success = yield updateDraft(flow, currentNodes, currentEdges);
            if (success) {
                yield publishFlow(flow.id);
                invalidateFlow();
            }
            return;
        }
        if (isConvertMode) {
            const { success: addSuccess, newFlow } = yield addFlow(title);
            let setConfigSuccess = false;
            if (addSuccess && newFlow)
                setConfigSuccess = yield updateDraft(newFlow, currentNodes, currentEdges);
            if (setConfigSuccess && newFlow) {
                yield publishFlow(newFlow.id);
                reloadForm(newFlow.id);
            }
            return;
        }
        if (isRenameMode && flow) {
            yield updateFlow(flow.id, title);
            onSetRenameModeOff === null || onSetRenameModeOff === void 0 ? void 0 : onSetRenameModeOff();
            return;
        }
        createDraft(title, reloadForm);
    });
    const handleGoBack = () => {
        var _a, _b;
        navigate(ROUTES.FLOWS, {
            state: isConvertMode && !flow
                ? {
                    openMailginsModal: true,
                    trigger: (_a = location.state) === null || _a === void 0 ? void 0 : _a.trigger,
                    mailingIds: (_b = location.state) === null || _b === void 0 ? void 0 : _b.mailingIds,
                }
                : {},
        });
    };
    const isDisabled = isCreatingDraft ||
        isUpdatingDraft ||
        isPublishingFlow ||
        isUpdatingFlow ||
        isInvalidatingFlow ||
        isAddingFlow;
    useNotificateForUnsavedForm(!skipLocationChangeWarning &&
        !isConvertMode &&
        !flow &&
        currentNodes.length > 0, {
        agreeLabel: t('Остаться в редакторе'),
        disagreeLabel: t('Не сохранять'),
    });
    return (_jsxs("div", Object.assign({ className: styles.formWrapper }, { children: [isConvertMode && (_jsxs("div", Object.assign({ className: styles.formTitleWrapper }, { children: [_jsx("div", Object.assign({ className: styles.formTitle }, { children: t('Новая цепочка из авторасылок') })), _jsxs("div", Object.assign({ className: styles.formDescription }, { children: [t('Проверьте, все ли желаемые авторассылки включены в цепочку'), ".", ' ', t('После сохранения рассылки на этом холсте ' +
                                'будут полностью перемещены из раздела Рассылки в Цепочки')] }))] }))), _jsxs("div", Object.assign({ className: styles.form }, { children: [_jsx(Input, { ref: titleInput, wrapperClassName: styles.input, type: 'text', placeholder: t('Название цепочки'), value: title, onChange: (e) => {
                            setTitle(e.target.value);
                            setHasError(isSubmitted ? !e.target.value.trim() : false);
                        }, onKeyUp: (e) => {
                            if (!isDisabled && e.code === 'Enter')
                                handleSubmit();
                        }, hasError: hasError, autoFocus: true }), _jsx(Button, Object.assign({ type: 'button', theme: 'primary', onClick: handleSubmit, disabled: isDisabled }, { children: isConvertMode ? t('Конвертировать') : t('Сохранить цепочку') })), _jsx(Button, Object.assign({ type: 'button', theme: 'default', onClick: isRenameMode ? onSetRenameModeOff : handleGoBack, disabled: isDisabled }, { children: t('Отмена') }))] }))] })));
};
