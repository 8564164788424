import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { ChannelCircles } from '@/components/ChannelCircles/ChannelCircles';
import { MailingRecipients } from '@/components/MailingRecipients/MailingRecipients';
import { MailingRevenueProfitHelp } from '@/components/MailingRevenueProfitHelp/MailingRevenueProfitHelp';
import { MailingStatusIcon } from '@/components/MailingStatusIcon/MailingStatusIcon';
import { Table } from '@/components/Table/Table';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { TableProfitRevenueCell as ProfitRevenue } from '@/components/TableProfitRevenueCell/TableProfitRevenueCell';
import { TableRowIcon } from '@/components/TableRowIcon/TableRowIcon';
import { MailingStats } from '@/icons/MailingStats';
import { MAILING_STATUS_DONE, MAILING_STATUS_DRAFT, MAILING_STATUS_PLANNED, } from '@/types/mailingStatus';
import { cr } from '@/utils/cr';
import { numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
import { getMailingDate } from './getMailingDate';
import { MailingFlowIcon } from './MailingFlowIcon';
import styles from './styles.module.scss';
export const MailingTable = memo(({ mailings = [], total, isManual, shouldDisplayProfit, currency, isLoading, purchaseUrl, onMailingStatsClick, onEditMailing, onCloneMailing, onDeleteMailing, onOpenPurchaseFilter, onSegmentClick, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasMailingsManage = userHasAccessTo([
        'MANUAL_MAILINGS_MANAGE',
        'AUTO_MAILINGS_MANAGE',
    ]);
    const isEditAction = (status) => !isManual ||
        status === MAILING_STATUS_PLANNED ||
        status === MAILING_STATUS_DRAFT;
    const shouldDisplayEditOrViewButton = (status) => isEditAction(status)
        ? // Кнопка Редактировать
            userHasAccessTo(['MANUAL_MAILINGS_MANAGE', 'AUTO_MAILINGS_MANAGE'])
        : // Кнопка Посмотреть
            userHasAccessTo([
                'MANUAL_MAILINGS_LIST',
                'MANUAL_MAILINGS_MANAGE',
                'AUTO_MAILINGS_LIST',
                'AUTO_MAILINGS_MANAGE',
            ]);
    const openModal = (mailing) => {
        onMailingStatsClick(mailing);
    };
    const handleRowClick = (row) => {
        const clickedMailing = mailings.find((mailing) => mailing.id === row.id);
        if (clickedMailing)
            onEditMailing(clickedMailing);
    };
    const columns = [
        {
            id: 'title',
            title: t('Название'),
        },
        {
            id: 'channels',
            title: t('Каналы'),
        },
        {
            id: 'roi',
            title: t('ROI'),
            tooltip: t(`TOOLTIP_ROI_${shouldDisplayProfit ? 'PROFIT' : 'REVENUE'}`),
            align: 'right',
        },
        {
            id: 'cr',
            title: t('CR'),
            tooltip: t('TOOLTIP_MAILING_CR'),
            align: 'right',
        },
        {
            id: 'profitOrRevenue',
            title: t(shouldDisplayProfit ? 'Прибыль' : 'Выручка'),
            tooltip: _jsx(MailingRevenueProfitHelp, {}),
            align: 'right',
        },
        {
            id: 'expenses',
            title: t('Расходы'),
            tooltip: t('Сумма затрат на рассылку за выбранный период. Учитываются списания бонусов, скидки и затраты на рассылки по всем каналам'),
            align: 'right',
        },
        {
            id: 'recipients',
            title: t('Получатели'),
            align: 'left',
        },
        ...(isManual ? [{ id: 'date', title: t('Дата') }] : []),
        { id: 'modal-button' },
        { id: 'actions' },
    ];
    const rows = mailings.map((mailing) => ({
        id: mailing.id,
        isDisabled: !!mailing.flowId,
        cells: [
            _jsxs("span", Object.assign({ className: styles.title }, { children: [isManual && (_jsxs(_Fragment, { children: [_jsx(MailingStatusIcon, { status: mailing.status }), _jsx(_Fragment, { children: "\u00A0" })] })), !isManual && mailing.isActive === false && (_jsxs(_Fragment, { children: [_jsx(TableRowIcon, { title: t('Остановлена'), icon: 'pause' }), _jsx(_Fragment, { children: "\u00A0" })] })), _jsxs("span", Object.assign({ className: classNames(styles.mailingName, {
                            [styles.hasFlowIcon]: !!mailing.flowId,
                        }) }, { children: [_jsx("span", Object.assign({ className: styles.wrap }, { children: mailing.name })), _jsx(_Fragment, { children: "\u00A0" }), _jsx(_Fragment, { children: "\u00A0" }), !!mailing.flowId && _jsx(MailingFlowIcon, { flowId: mailing.flowId })] }))] }), 'title'),
            _jsx(ChannelCircles, { channels: mailing.channels }, 'channels'),
            numberRound(roi(shouldDisplayProfit ? mailing.profit : mailing.revenue, mailing.expenses)) + '%',
            numberRound(cr(mailing.goals, mailing.sendings), 2) + '%',
            _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: Number(mailing.profit), revenue: Number(mailing.revenue), currency: currency, href: purchaseUrl, onClick: () => onOpenPurchaseFilter(mailing) }, 'profitOrRevenue'),
            _jsx(Expenses, { expenses: Number(mailing.expenses), currency: currency }, 'expenses'),
            _jsx(MailingRecipients, { recipients: mailing.recipients, excludedRecipients: mailing.excludeRecipients, onSegmentClick: onSegmentClick }, 'recipients'),
            isManual ? getMailingDate(mailing) : null,
            _jsx("button", Object.assign({ className: styles.modalButton, type: 'button', onClick: (e) => {
                    e.stopPropagation();
                    openModal(mailing);
                } }, { children: _jsx(MailingStats, {}) }), 'modal-button'),
        ].filter((cell) => cell !== null),
        actionsCellClassName: styles.actionsColumn,
        actions: [
            {
                id: 'edit',
                label: isEditAction(mailing.status)
                    ? t('Редактировать')
                    : t('Посмотреть'),
                onClick: () => onEditMailing(mailing),
                isDisabled: !shouldDisplayEditOrViewButton(mailing.status),
                tooltip: !shouldDisplayEditOrViewButton(mailing.status) &&
                    t('Недоступно для вашей роли'),
            },
            {
                id: 'clone',
                label: t('Сделать копию'),
                onClick: () => onCloneMailing(mailing),
                isDisabled: !userHasMailingsManage,
                tooltip: !userHasMailingsManage && t('Недоступно для вашей роли'),
            },
            {
                id: 'delete',
                label: t('Удалить'),
                onClick: () => onDeleteMailing(mailing),
                isDanger: true,
                isDisabled: !userHasMailingsManage,
                tooltip: !userHasMailingsManage && t('Недоступно для вашей роли'),
                shouldDisplay: mailing.status !== MAILING_STATUS_DONE,
            },
        ],
    }));
    const totalRow = [
        {
            id: 'total',
            isTotal: true,
            cells: [
                _jsx(Fragment, { children: _jsx("b", { children: t('Итого за период') }) }, 'total'),
                '',
                numberRound(total.roi) + '%',
                numberRound(total.cr, 2) + '%',
                _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: total.profit, revenue: total.revenue, currency: currency }, 'profitOrRevenue'),
                _jsx(Expenses, { expenses: total.expenses, currency: currency }, 'expenses'),
                '',
                '',
                isManual ? '' : null,
                '',
            ].filter((cell) => cell !== null),
        },
    ];
    return (_jsx(Table, { className: styles.table, cellClassNames: {
            1: styles.channelColumn,
            [!isManual ? 7 : 8]: styles.modalColumn,
        }, isLoading: isLoading, columns: columns, rows: rows, total: totalRow, onRowClick: handleRowClick, hasEmptyState: true }));
});
