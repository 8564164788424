import { useEffect } from 'react';
import { useGetAutoMailingListQuery, useGetManualMailingListQuery, } from '@/api/query/mailings';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
const CHECK_AGAIN_TIMEOUT = 60000;
export const useMailingList = (isManual, searchText) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data: autoMailings, isLoading: isLoadingAutoMailings, isFetching: isFetchingAutoMailings, refetch: refetchAutoMailings, requestId: autoMailingsRequestId, } = useGetAutoMailingListQuery({
        searchText,
        periodStart,
        periodEnd,
    }, {
        skip: isManual,
        refetchOnMountOrArgChange: true,
    });
    const { data: manualMailings, isLoading: isLoadingManualMailings, isFetching: isFetchingManualMailings, refetch: refetchManualMailings, requestId: manualMailingsRequestId, } = useGetManualMailingListQuery({
        searchText,
        periodStart,
        periodEnd,
    }, {
        skip: !isManual,
        refetchOnMountOrArgChange: true,
    });
    const mailings = isManual ? manualMailings : autoMailings;
    const isLoading = isManual ? isLoadingManualMailings : isLoadingAutoMailings;
    const isFetching = isManual
        ? isFetchingManualMailings
        : isFetchingAutoMailings;
    const refetch = isManual ? refetchManualMailings : refetchAutoMailings;
    const requestId = isManual ? manualMailingsRequestId : autoMailingsRequestId;
    useEffect(() => {
        const hasUnfinishedMailings = mailings === null || mailings === void 0 ? void 0 : mailings.some((m) => m.status !== 2 && m.status !== 3 && m.status !== 5);
        const checkAgainTimeout = hasUnfinishedMailings
            ? setTimeout(refetch, CHECK_AGAIN_TIMEOUT)
            : undefined;
        return () => {
            if (checkAgainTimeout)
                clearTimeout(checkAgainTimeout);
        };
    }, [requestId, mailings]);
    return {
        mailings: moveFlowMailingsBottom(mailings || []),
        isLoading,
        isFetching,
    };
};
function moveFlowMailingsBottom(mailings) {
    const flowMailings = [];
    const otherMailings = [];
    mailings.forEach((m) => {
        if (m.flowId)
            flowMailings.push(m);
        else
            otherMailings.push(m);
    });
    return [...otherMailings, ...flowMailings];
}
