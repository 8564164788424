import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Plus } from '@/icons/Plus';
import { MailingRewardsPillsPopup } from './MailingRewardsPillsPopup';
import { BonusesAmount } from './pills/BonusesAmount';
import { GiftCard } from './pills/GiftCard';
import { Offer } from './pills/Offer';
import { Promocode } from './pills/Promocode';
import styles from './styles.module.scss';
export const MailingRewardsPills = ({ value, onChange, offers = [], promocodes = [], giftCards = [], currency, isDisabled, }) => {
    const { t } = useTranslation();
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.valueWrapper }, { children: [value.map((item, i) => {
                        const commonProps = {
                            key: `item-${item.type}-${i}`,
                            idx: i,
                            value,
                            onChange,
                            offers,
                            promocodes,
                            giftCards,
                            currency,
                            isDisabled,
                        };
                        if (item.type === 'bonuses')
                            return _jsx(BonusesAmount, Object.assign({}, commonProps));
                        if (item.type === 'promocode')
                            return _jsx(Promocode, Object.assign({}, commonProps));
                        if (item.type === 'gift-card')
                            return _jsx(GiftCard, Object.assign({}, commonProps));
                        if (item.type === 'offer')
                            return _jsx(Offer, Object.assign({}, commonProps));
                        return null;
                    }), _jsx("span", Object.assign({ ref: triggerRef, onClick: togglePopup }, { children: _jsx(Button, Object.assign({ type: 'button', icon: _jsx(Plus, {}), isActive: popupIsOpen, isOutlined: true, disabled: isDisabled }, { children: t('Добавить поощрение') })) }))] })), _jsx(MailingRewardsPillsPopup, { isOpen: popupIsOpen, onClose: togglePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, value: value, onChange: onChange, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency })] }));
};
