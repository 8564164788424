import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NewBadgeText } from '@/components/NewBadge/NewBadgeText';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Lock } from '@/icons/Lock';
import { AppNavButton } from '../AppNavButton/AppNavButton';
import styles from './styles.module.scss';
export const AppNavSectionName = memo(({ id, icon: iconRegular, iconAccent, name, isActive, isHovered, isCollapsed, onClick, externalLink, activeClassName, isLock, tooltip, hasNewsBadge = false, }) => {
    const { t } = useTranslation();
    const isExternal = !isCollapsed && !!externalLink;
    const handleClick = useCallback(() => {
        if (onClick)
            onClick(id);
    }, [id, onClick]);
    if (isLock && !isCollapsed)
        return (_jsx(Tooltip, Object.assign({ className: styles.tooltip, title: t(tooltip || ''), placement: 'right' }, { children: _jsx("span", { children: _jsxs(AppNavButton, Object.assign({ className: classNames(styles.section, {
                        [activeClassName || styles.active]: isActive,
                        [styles.hovered]: isHovered,
                    }), isActive: isActive, onClick: !isExternal ? handleClick : undefined, href: isExternal ? externalLink : undefined }, { children: [_jsx("span", Object.assign({ className: classNames(styles.icon, styles.regular) }, { children: iconRegular })), _jsx("span", Object.assign({ className: classNames(styles.icon, styles.accent) }, { children: iconAccent })), _jsx("span", Object.assign({ className: styles.name }, { children: t(name) })), _jsx("span", Object.assign({ className: styles.lockIcon }, { children: _jsx(Lock, {}) }))] })) }) })));
    return (_jsxs(AppNavButton, Object.assign({ className: classNames(styles.section, {
            [activeClassName || styles.active]: isActive,
            [styles.hovered]: isHovered,
            [styles.hasNewsBadge]: hasNewsBadge,
        }), isActive: isActive, onClick: !isExternal ? handleClick : undefined, href: isExternal ? externalLink : undefined }, { children: [_jsx("span", Object.assign({ className: classNames(styles.icon, styles.regular) }, { children: iconRegular })), _jsx("span", Object.assign({ className: classNames(styles.icon, styles.accent) }, { children: iconAccent })), _jsx("span", Object.assign({ className: styles.name }, { children: t(name) })), hasNewsBadge && _jsx(NewBadgeText, {})] })));
});
