import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useDrawerContent } from './useDrawerContent';
import { useDrawerFocus } from './useDrawerFocus';
export const NodeFormDrawer = () => {
    const { selectedNode, isOpen, close, closeOnEsc, icon, title, titleRightElement, children, bottomContent, footer, isLarge, isWide, } = useDrawerContent();
    const { container } = useDrawerFocus(selectedNode.uuid);
    return (_jsx(ClickAwayListener, Object.assign({ onClickAway: close }, { children: _jsxs("div", Object.assign({ ref: container, className: classNames(styles.drawer, {
                [styles.open]: isOpen,
                [styles.large]: isLarge,
                [styles.wide]: isWide,
            }), onKeyDown: closeOnEsc, tabIndex: -1 }, { children: [_jsx("div", Object.assign({ className: styles.header }, { children: _jsxs("div", Object.assign({ className: styles.headerContent }, { children: [_jsxs("div", Object.assign({ className: styles.titleContent }, { children: [icon, title] })), titleRightElement] })) })), _jsxs("div", Object.assign({ className: styles.body }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.content, {
                                [styles.hasBottomContent]: !!bottomContent,
                            }) }, { children: [children, bottomContent] }), selectedNode.uuid), !!footer && _jsx("div", Object.assign({ className: styles.footer }, { children: footer }))] }))] })) })));
};
