var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { useFlowValidation } from '@/components/flow/InteractiveFlow/hooks/useFlowValidation';
import { useToastContext } from '@/components/Toast/ToastContext';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { PlayAlt } from '@/icons/PlayAlt';
import { useInvalidateFlow } from './useInvalidateFlow';
import { usePublishFlow } from './usePublishFlow';
import { useStartFlow } from './useStartFlow';
export const StartButton = ({ flow, label, currentNodes = [], currentEdges = [], shouldStart = false, shouldPublish = false, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasFlowsManage = userHasAccessTo('FLOWS_MANAGE');
    const addToast = useToastContext();
    const [isLoading, setIsLoading] = useState(false);
    const { validateFlow } = useFlowValidation();
    const { startFlow } = useStartFlow();
    const { publishFlow } = usePublishFlow();
    const { invalidateFlow, isInvalidatingFlow } = useInvalidateFlow(flow === null || flow === void 0 ? void 0 : flow.id);
    const isDisabled = !userHasFlowsManage || isInvalidatingFlow || isLoading;
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const hasChannels = currentNodes.filter((node) => node.type === 'channel').length > 0;
        if (!hasChannels || !currentEdges.length) {
            addToast({ type: 'error', message: t('Добавьте хотя бы одну рассылку') });
            return;
        }
        setIsLoading(true);
        let publishSuccess = true;
        if (shouldPublish) {
            publishSuccess = validateFlow();
            if (!publishSuccess)
                addToast({ type: 'error', message: t('Заполните поля и блоки') });
            else
                publishSuccess = yield publishFlow(flow.id);
            if (publishSuccess && !shouldStart)
                invalidateFlow();
        }
        if (shouldStart && publishSuccess) {
            yield startFlow(flow.id);
            invalidateFlow();
        }
        setIsLoading(false);
    });
    return (_jsx(Tooltip, Object.assign({ title: !userHasFlowsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(PlayAlt, {}), theme: 'primary', onClick: handleClick, disabled: isDisabled }, { children: label })) })));
};
