import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { RowExpand } from '@/icons/RowExpand';
import styles from './styles.module.scss';
import { TableRowActions } from './TableRowActions';
import { TableRowCell } from './TableRowCell';
export const TableBody = ({ id, className, type = 'main-group', rows = [], columns = [], cellClassNames, needAdaptive, onRowClick, onRowEnter, onRowLeave, childGroup, childGroupExpanded, onToggleChild, activeRowId, onChangeActiveRowId, hasClicksOnSubRows = false, }) => {
    if (!rows.length)
        return null;
    let handleRowClick = onRowClick;
    if (hasClicksOnSubRows && type === 'main-group')
        handleRowClick = onToggleChild;
    if (!hasClicksOnSubRows && type === 'sub-group')
        handleRowClick = undefined;
    return (_jsx("tbody", Object.assign({ id: id, className: className }, { children: rows.map((row, rowIndex) => (_jsxs("tr", Object.assign({ className: classNames({
                [styles.subRow]: type === 'sub-group',
                [styles.active]: row.id === activeRowId || row.isActive,
                [styles.disabled]: row.isDisabled,
                [styles.tbodyNeedAdaptive]: needAdaptive,
            }), onMouseEnter: () => onRowEnter === null || onRowEnter === void 0 ? void 0 : onRowEnter(rowIndex), onMouseLeave: () => onRowLeave === null || onRowLeave === void 0 ? void 0 : onRowLeave(rowIndex) }, { children: [row.cells.map((cellObject, cellIndex) => {
                    var _a;
                    const content = typeof cellObject === 'object' &&
                        cellObject &&
                        'content' in cellObject
                        ? cellObject.content
                        : cellObject;
                    const colspan = typeof cellObject === 'object' &&
                        cellObject &&
                        'colspan' in cellObject
                        ? cellObject.colspan
                        : undefined;
                    return (_jsx(TableRowCell, Object.assign({ className: cellClassNames === null || cellClassNames === void 0 ? void 0 : cellClassNames[cellIndex], column: columns[cellIndex], row: row, needAdaptive: needAdaptive && cellIndex === 0, isSubCell: type === 'sub-group', colspan: colspan, onClick: !row.isDisabled && handleRowClick
                            ? () => handleRowClick === null || handleRowClick === void 0 ? void 0 : handleRowClick(row, rowIndex)
                            : undefined }, { children: !!childGroup && rowIndex === 0 && cellIndex == 0 ? (_jsxs("div", Object.assign({ className: styles.nowrap }, { children: [_jsx("span", Object.assign({ className: styles.wrap }, { children: content })), _jsx(_Fragment, { children: "\u00A0" }), _jsxs("button", Object.assign({ className: styles.expandButton, onClick: (e) => {
                                        e.stopPropagation();
                                        onToggleChild === null || onToggleChild === void 0 ? void 0 : onToggleChild();
                                    } }, { children: [_jsx("span", Object.assign({ className: styles.count }, { children: childGroup.rows.length })), _jsx(RowExpand, { className: styles.expandIcon, expanded: childGroupExpanded })] }))] }))) : (content) }), ((_a = columns[cellIndex]) === null || _a === void 0 ? void 0 : _a.id) || `cell-${cellIndex}`));
                }), _jsx(TableRowActions, { row: row, onOpen: (rowId) => {
                        onChangeActiveRowId === null || onChangeActiveRowId === void 0 ? void 0 : onChangeActiveRowId(rowId);
                    }, onClose: () => {
                        if (row.id === activeRowId)
                            onChangeActiveRowId === null || onChangeActiveRowId === void 0 ? void 0 : onChangeActiveRowId(null);
                    }, isSubCell: type === 'sub-group' }, 'actions')] }), row.id))) })));
};
